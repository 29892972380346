<template>
    <div >
        <con-tab ></con-tab>
        <need-jc class="nei" ></need-jc>
    </div>
</template>

<script>
	import ConTab from "../../components/common/ConTab";
    import NavBar from "../../components/common/faNavBar";
    import NeedJc from "./base/NeedJc";
    export default {
        name: "Needjc",
        components:{
            NavBar,
            NeedJc,
			ConTab
        }
    }
</script>

<style scoped lang="less">
	
    .nei{
        margin-top: 6px;
    }
</style>